import axios from 'axios';
import router from '@/router';

const BASEURL = 'https://crjyxspt.zikaoj.com/api/v1';

// const BASEURL = 'http://127.0.0.1:8000/api/v1';

// const BASEURL = 'http://192.168.0.7:8088/api/v1';

// const BASEURL = 'https://ceshi.zikaoj.com:9088/api/v1';

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(BASEURL + url, {
            // 传递参数
            params: params
            // 设置请求头信息，可以传递空值
        }).then(response => {
            // 请求成功
            resolve(response.data);
            // token失效返回登录页
            if (response.data.code === 10004) {
                localStorage.removeItem('myToken');
                return router.push({path: '/'});
            }
        }).catch(error => {
            // 请求失败，
            reject(error);
        });
    });
}

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(BASEURL + url, data).then(response => {
            // 请求成功
            resolve(response.data);
            // token失效返回登录页
            if (response.data.code === 10004) {
                localStorage.removeItem('myToken');
                return router.push({path: '/'});
            }
        }).catch(error => {
            // 请求失败，
            reject(error);
        });
    });
}

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(BASEURL + url, data).then(response => {
            // 请求成功
            resolve(response.data);
            // token失效返回登录页
            if (response.data.code === 10004) {
                localStorage.removeItem('myToken');
                return router.push({path: '/'});
            }
        }).catch(error => {
            // 请求失败，
            reject(error);
        });
    });
}

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(BASEURL + url, data).then(response => {
            // 请求成功
            resolve(response.data);
            // token失效返回登录页
            if (response.data.code === 10004) {
                localStorage.removeItem('myToken');
                const loginUrl = localStorage.getItem('loginUrl');
                return router.push({path: '/'});
            }
        }).catch(error => {
            // 请求失败，
            reject(error);
        });
    });
}

export function del(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(BASEURL + url, data).then(response => {
            // 请求成功
            resolve(response.data);
            // token失效返回登录页
            if (response.data.code === 10004) {
                localStorage.removeItem('myToken');
                return router.push({path: '/'});
            }
        }).catch(error => {
            // 请求失败，
            reject(error);
        });
    });
}

import { get, put } from '@/utils/http.js';

/*
function:获取消息信息(没有token)
author:sjy
time:2021/03/16
*/

export function getMessage(data: any) {
    return get('/announce/massage', data);
}

/*
function:提交首页信息(没有token)
author:sjy
time:2021/0/16
*/

export function putSaveState(data: any) {
    return put('/announce/massage', data);
}

/*
function:获取通告详情
author:Ysk
time:2024/04/28
*/

export function getContent(data: any) {
    return get('/announce/massageInfo', data);
}













































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class About extends Vue {
  academyName = '';

  created() {
      const student = JSON.parse(localStorage.getItem('student') as string);
      if (student != null) {
          this.academyName = student.academy_name;
      }
  }
}




























































































import {Component, Vue} from 'vue-property-decorator';
import {getMessage} from '@/api/myNews/myNews.ts';

@Component
export default class About extends Vue {
  data = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    semester_id: 0
  };

  semesterList = ['第一学期', '第二学期', '第三学期', '第四学期', '第五学期', '第六学期'];
  // logo
  webLogo = '';
  // 年份
  year = '';
  // 学生名字
  name = '';
  // 学校名字
  academyName = '';
  // 专业名字
  majorName = '';
  // 学期
  semester = '';
  // 头像
  gender = '';
  // 当前被选中路由
  nowRoute = '';
  // 用户头像
  userImg = '';
  // 当前学期数
  forIndex = 1;
  // 学生账号
  phone = '';
  // 学生
  studylevel = 0;
  // 弹窗状态
  popupState = false;
  titleList = [{
    name: '课程作业',
    router: '/courseWork'
  },
    {
      name: '直播课堂',
      router: '/liveCourse'
    },
    {
      name: '我的考试',
      router: '/myExam'
    }, {
      name: '我的成绩',
      router: '/myGrades'
    },
    // {
    //     name: '我的论文',
    //     router: '/myThesis'
    // },
    {
      name: '我的学籍',
      router: '/myStudentStatus'
    }, {
      name: '我的消息',
      router: '/myNews',
      message: 0
    }
    // {
    //     name: '资料审核',
    //     router: '/dataReview'
    // }
  ];

  created() {
    // 获取学期id
    // eslint-disable-next-line @typescript-eslint/camelcase
    const student = JSON.parse(localStorage.getItem('student') as string);
    let semIndex = 0;
    this.year = student.grade;
    this.name = student.name;
    this.majorName = student.major_name;
    this.academyName = student.academy_name;
    this.webLogo = student.web_logo_url;
    this.gender = student.gender;
    this.userImg = student.user_img;
    this.forIndex = student.semesterList.length;
    this.phone = student.phone;
    this.studylevel = student.study_level;
    const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
    if (checksem !== null) {
      this.data.semester_id = parseInt(checksem.id);
      semIndex = parseInt(checksem.semester);
    } else {
      this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
      semIndex = student.semester;
    }
    if (this.academyName !== '广东开放大学') {
      this.titleList = [{name: '课程作业', router: '/courseWork'}, {name: '我的考试', router: '/myExam'}, {
        name: '我的成绩',
        router: '/myGrades'
      }, {name: '我的学籍', router: '/myStudentStatus'}, {name: '我的消息', router: '/myNews', message: 0},];
    }

    if (this.academyName == '江西应用科技学院' && this.studylevel >= 1
    ) {
      this.titleList.push({
        name: '学位英语报名',
        router: '/degreeEnglish'
      });
    }


    switch (semIndex) {
      case 1:
        this.semester = '第一学期';
        break;
      case 2:
        this.semester = '第二学期';
        break;
      case 3:
        this.semester = '第三学期';
        break;
      case 4:
        this.semester = '第四学期';
        break;
      case 5:
        this.semester = '第五学期';
        break;
      case 6:
        this.semester = '第六学期';
        break;
    }
    this.nowRoute = this.$route.path;
    this.getNowRoute();
    this.getData();
  }

  // 切换标题
  titleChange(router: string) {
    if (this.nowRoute === this.$route.path) {
      if (this.nowRoute === router) {
        return;
      }
    }

    this.nowRoute = this.$route.path;
    this.$router.push(router);
    this.getNowRoute();
  }

  // 获取被选中的路由
  getNowRoute() {
    switch (this.$route.path) {
      case '/courseVideo':
      case '/myHomework' :
      case '/answerSheet':
      case '/answerReport':
      case '/answerAnalysis':
        this.nowRoute = '/courseWork';
        break;
      case '/examinationAnswer':
        this.nowRoute = '/myExam';
        break;
    }
  }

  // 退出登录
  loginOut() {
    this.popupState = true;
  }

  clickSelect(index: any) {
    this.semester = this.semesterList[index];
    const student = JSON.parse(localStorage.getItem('student') as string);
    localStorage.setItem('checkSemester', JSON.stringify(student.semesterList[index]));
    this.$router.go(0);
  }

  // 确定弹窗
  openPopup() {
    const poUrl = localStorage.getItem('loginUrl');
    localStorage.clear();
    if (poUrl) {
      localStorage.setItem('loginUrl', poUrl);
    } else {
      localStorage.setItem('loginUrl', '');
    }

    this.popupState = false;
    // eslint-disable-next-line eqeqeq

    this.$router.push('/');
  }

  // 关闭弹窗
  closePopup() {
    this.popupState = false;
  }

  getData() {
    getMessage(this.data).then((res: any) => {
      this.titleList[4].message = res.msg_num;
      // console.log(this.messageNum, 'test');
    }).catch((error: any) => {
      console.log(error);
    });
  }
}
